// React
import React, { Component } from 'react'

// Components
import PopupTrigger from './popup/popup_trigger'
import OrderPickupReturn from './OrderPickupReturn'

class DuplicatePopup extends Component {
  static displayName = 'DuplicatePopup'

  static propTypes = {}

  constructor(props) {
    super(props)

    this.state = {
      selectedStockIds: this.props.plannings.map((planning) => planning.id),
      allSelected: true,
      buttonDisabled: true,
      loading: false,
      error: null
    }
  }

  handleSelectAllStock = () => {
    const planningIds = this.props.plannings.map((planning) => planning.id)

    this.setState({
      allSelected: !this.state.allSelected,
      selectedStockIds: this.state.allSelected ? [] : planningIds
    })
  }

  selectStock = (id) => {
    let newPlannings

    const index = this.state.selectedStockIds.indexOf(id)

    if (index === -1) {
      newPlannings = [...this.state.selectedStockIds, id]
    } else {
      newPlannings = this.state.selectedStockIds.filter(ids => ids !== id)
    }

    this.setState({
      selectedStockIds: newPlannings,
      allSelected: newPlannings.length == this.props.plannings.length
    })
  }

  handleDatePicker = (values) => {
    this.setState({ buttonDisabled: false, ...values })
  }

  handleSubmit = () => {
    const params = {
      return_at: this.state.return_at,
      pickup_at: this.state.pickup_at,
      return_depot_id: this.state.return_depot_id,
      pickup_depot_id: this.state.pickup_depot_id,
      stock_planning_ids: this.state.selectedStockIds
    }

    this.setState({ loading: true })

    request.post(`/api/2/orders/${this.props.order_id}/duplicate`, params)
      .then(({ data }) => {
        this.redirectToNewOrder(data.order)
      })
      .catch(({ response }) => {
        this.setState({ error: response.data, loading: false })
      })
  }

  handleTryAgain = () => {
    this.setState({ error: null })
  }

  redirectToNewOrder = ({ id }) => {
    Turbo.visit(`/reservations/${id}/edit`)
  }

  renderError = (errorData) => {
    console.log(errorData)
    const errors = errorData.error_data.errors
    const stockItems = errors.map((error) => error.stock)

    return (
      <div className="duplicate-popup">
        <div className="popup-body">
          <h1 className="title">{I18n.t('javascripts.duplicate.stock_items_cannot_be_booked')}</h1>
          {stockItems.map((stock) => (
            <div key={stock.id}>
              <strong>{stock.number}</strong> {stock.name}
              <br />
              <br />
            </div>
          ))}
        </div>
        <div className="buttons">
          <button className="button" type="button" onClick={this.props.onCancel}>{I18n.t('javascripts.cancel')}</button>
          <button className="button green" type="button" onClick={this.handleTryAgain}>{I18n.t('javascripts.duplicate.try_again')}</button>
        </div>
      </div>
    )
  }

  renderPlanning = ({ id, stock }) => {
    return (
      <tr key={id} className={stock.returned && 'returned'}>
        <td className="checkbox">
          <input
            type="checkbox"
            className="select"
            checked={this.state.selectedStockIds.indexOf(id) !== -1}
            onChange={this.selectStock.bind(null, id)}
          />
        </td>
        <td className="number center">{stock.number}</td>
        <td>
          {stock.returned && <div className="returned-notice">{stock.returned_at}</div>}
          {stock.name}
          {stock.serial_number && <strong> - {stock.serial_number}</strong>}
        </td>
      </tr>
    )
  }

  render () {
    const { order_id, plannings, label } = this.props

    if (this.state.error) {
      return this.renderError(this.state.error)
    } else if (this.state.loading) {
      return (
        <div className="loading-popup">
          <div id="body">
            {I18n.t('javascripts.duplicate.busy_duplicating_products', { products_count: this.state.selectedStockIds.length })}
            <div className="loading"><i className="fa fa-spinner" /></div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="duplicate-popup">
          <a id="close" href="#" className="fa fa-close" onClick={this.props.onCancel} />
          <div className="popup-body">
            <h1 className="title">{I18n.t('javascripts.duplicate.title')}</h1>
            <OrderPickupReturn
              order_id={order_id}
              onChange={this.handleDatePicker}
              pickerOrientation="bottom"
              icon="arrow-right"
            />

            <p className="title">{I18n.t('javascripts.duplicate.products_selected', { products_count: this.state.selectedStockIds.length })}</p>
            <table>
              <thead>
                <tr>
                  <th className="checkbox">
                    <input
                      type="checkbox"
                      id="select_all"
                      onChange={this.handleSelectAllStock}
                      checked={this.state.allSelected}
                    />
                  </th>
                  <th></th>
                  <th>{I18n.t('javascripts.duplicate.name')}</th>
                </tr>
              </thead>
              <tbody>
                { plannings.map(this.renderPlanning) }
              </tbody>
            </table>
          </div>
          <div className="buttons">
            <button className="button" type="button" onClick={this.props.onCancel}>{I18n.t('javascripts.cancel')}</button>
            <button className="button green" type="button" onClick={this.handleSubmit} disabled={this.state.buttonDisabled}>{label}</button>
          </div>
        </div>
      )
    }
  }
}

class DuplicateButton extends Component {
  static displayName = 'DuplicateButton'

  static propTypes = {}

  constructor(props) {
    super(props)
  }

  renderPopup = () => {
    return (
      <DuplicatePopup
        order_id={this.props.order_id}
        plannings={this.props.plannings}
        label={this.props.label}
      />
    )
  }

  render () {
    return (
      <PopupTrigger component={this.renderPopup()}>
        <a href="#" className="button">{this.props.label || I18n.t('javascripts.duplicate.button')}</a>
      </PopupTrigger>
    )
  }
}

export default DuplicateButton
