// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect as reduxConnect } from 'react-redux'
import moment from 'moment'

import BookError from './BookError'

class NoRoutesAvailableError extends Component {
  static displayName = 'NoRoutesAvailableError'

  static propTypes = {
    error: PropTypes.object
  }

  render = () => {
    return (
      <BookError {...this.props} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const stockIds = []
  const metadata = {}

  props.error.data.transports.forEach((t) => {
    stockIds.push(t.stock_id)

    if (t.first_available_at) {
      metadata[t.stock_id] = `${I18n.t('javascripts.book_error.first_available_date')} ${moment(t.first_available_at).format('DD-MM-YYYY HH:mm')}`
    }
  })

  return {
    stocks: state.orm.stocks.getAll({ id: stockIds }),
    metadata
  }
}

export default reduxConnect(mapStateToProps)(NoRoutesAvailableError)
