import some from 'lodash/some'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'

const diffInDays = (date1, date2) => {
  return Math.abs(moment(date1).clone().startOf('day').diff(moment(date2).clone().startOf('day')))
}

const timeAsInteger = (datetime) => {
  datetime = moment(datetime)
  return (datetime.hours() * 100) + datetime.minutes()
}

const timeStringAsInteger = (timeString) => {
  const [hourString, minuteString] = timeString.split(':')
  return (parseInt(hourString) * 100) + parseInt(minuteString)
}

const openingTimesForDepot = (depot, openingTimes) => {
  return openingTimes.getAll({ depot_id: [depot.id, null, undefined], domain_id: [depot.domain_id, null, undefined] })
}

/**
 * Returns whether given day is open for the given depot.
 */
export const isDayOpen = (date, depot, openingTimes) => {
  const depotOpeningTimes = openingTimesForDepot(depot, openingTimes)
  const dateOpeningTimes = depotOpeningTimes.filter((o) => o.date && o.date === date.format('YYYY-MM-DD'))
  const recurringOpeningTimes = depotOpeningTimes.filter((o) => !o.date && o.weekday === date.day())

  // Check by date
  const dateOpen = every(dateOpeningTimes.toArray(), (openingTime) => {
    const entireDay = isEmpty(trim(openingTime.from_time)) && isEmpty(trim(openingTime.until_time))

    if (entireDay) {
      return openingTime.open
    } else {
      return true
    }
  })

  if (!dateOpen) {
    return false
  }

  // Check by recurring day of the week
  return some(recurringOpeningTimes.toArray(), (openingTime) => {
    return openingTime.open
  })
}

/**
 * Returns whether given timeslot is open for the given depot and date.
 */
export const isTimeslotOpen = (timeslot, date, depot, openingTimes) => {
  // Entire day is closed
  if (!isDayOpen(date, depot, openingTimes)) {
    return false
  }

  // Find relevant opening times that have a time window set
  const depotOpeningTimes = openingTimesForDepot(depot, openingTimes).filter((openingTime) => {
    const entireDay = isEmpty(trim(openingTime.from_time)) && isEmpty(trim(openingTime.until_time))
    return !entireDay && openingTime.weekday === date.day() || openingTime.date === date.format('YYYY-MM-DD')
  })

  const timeslotFrom = timeStringAsInteger(timeslot.from_time)
  const timeslotTill = timeStringAsInteger(timeslot.till_time)

  return every(depotOpeningTimes.toArray(), (openingTime) => {
    const from = timeStringAsInteger(openingTime.from_time)
    const till = timeStringAsInteger(openingTime.until_time)

    // Timeslot should overlap the depot opening times
    const withinOpeningTime = Math.max(from, timeslotFrom) < Math.min(till, timeslotTill)

    if (openingTime.open) {
      return withinOpeningTime
    } else {
      return !withinOpeningTime
    }
  })
}

/**
 * Returns whether the given day is disabled for given options.
 */
export const isDayDisabled = ({ day, type, pickupAt, returnAt, pickupDepotId, returnDepotId, openingTimes, depots }) => {
  const date = moment(day)

  // Disabled past
  if (date < moment().startOf('day')) {
    return true
  }

  // Return day not before pickup day
  if (type === 'return' && date.startOf('day') < pickupAt.clone().startOf('day')) {
    return true
  }

  // Closed pickup day
  if (type === 'pickup' && !isDayOpen(date, depots.getById(pickupDepotId), openingTimes)) {
    return true
  }

  // Closed return day
  if (type === 'return' && !isDayOpen(date, depots.getById(returnDepotId), openingTimes)) {
    return true
  }

  return false
}

/**
 * Returns whether the given timeslot is disabled for given options.
 */
export const isTimeslotDisabled = ({ timeslot, type, pickupAt, returnAt, pickupDepotId, returnDepotId, openingTimes, depots }) => {
  // Disable past pickups
  if (type === 'pickup' && diffInDays(pickupAt, moment()) === 0) {
    if (timeslot.till_time_integer < timeAsInteger(moment())) {
      return true
    }
  }

  // Pickup and return are on the same day
  if (diffInDays(pickupAt, returnAt) === 0) {
    // Disable pickup time later than return time
    if (type === 'pickup') {
      if (timeslot.till_time_integer > timeAsInteger(returnAt)) {
        return true
      }
    }

    // Disable return time earlier than pickup time
    if (type === 'return') {
      if (timeslot.from_time_integer < timeAsInteger(pickupAt)) {
        return true
      }
    }
  }

  // Closed pickup timeslot
  if (type === 'pickup' && !isTimeslotOpen(timeslot, pickupAt, depots.getById(pickupDepotId), openingTimes)) {
    return true
  }

  // Closed return timeslot
  if (type === 'return' && !isTimeslotOpen(timeslot, returnAt, depots.getById(returnDepotId), openingTimes)) {
    return true
  }

  return false
}

/**
 * Returns whether the given depot is disabled for given options.
 */
export const isDepotDisabled = ({ depot, type, pickupAt, returnAt, openingTimes }) => {
  // Closed pickup
  if (!isDayOpen(pickupAt, depot, openingTimes) && type === 'pickup') {
    return true
  }

  // Closed return
  if (!isDayOpen(returnAt, depot, openingTimes) && type === 'return') {
    return true
  }

  return false
}
