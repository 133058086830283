const React = require('react')
const createReactClass = require('create-react-class')

const Planning = createReactClass({
  displayName: 'Planning',
  mixins: [PureAwesomeMixin],

  componentDidMount () {
    this.checkWidth()
  },

  componentDidUpdate () {
    this.checkWidth()
  },

  cssClasses () {
    const classes = ['item', 'type-planning', this.props.className]

    if (this.pickupDepot().id !== this.returnDepot().id) {
      classes.push('different-depot')
    }
    if (!this.props.planning.locked) {
      classes.push('unlocked')
    }
    if (this.props.planning.shaded) {
      classes.push('shaded')
    }
    if (this.props.inOrder) {
      classes.push('in_order')
    }
    if (!this.props.planning.order_id && !this.props.planning.transport_id) {
      classes.push('manual-planning')
    }
    if (this.props.planning.order_id) {
      classes.push('type-order')
    }

    return classes.join(' ')
  },

  checkWidth () {
    // TODO: Do not use findDOMNode. It doesn't work with function components and is deprecated in StrictMode
    // eslint-disable-next-line react/no-find-dom-node
    const element = $(ReactDOM.findDOMNode(this))

    if (element.width() < 40) {
      element.addClass('small')
    } else {
      element.removeClass('small')
    }
  },

  handleDoubleClick () {
    window.open(`/reservations/${this.props.planning.order_id}/edit`)
  },

  pickupDepot () {
    if (this.props.inOrder && this.props.order) {
      return depots[this.props.order.pickup_depot_id]
    } else {
      return depots[this.props.planning.pickup_depot_id]
    }
  },

  returnDepot () {
    if (this.props.inOrder && this.props.order) {
      return depots[this.props.order.return_depot_id]
    } else {
      return depots[this.props.planning.return_depot_id]
    }
  },

  renderManualPlanning () {
    const pickupDepot = this.pickupDepot()

    if (pickupDepot.depot_type === 'repair') {
      icon = <i className="fa fa-ambulance" />
    } else {
      icon = <i className="fa fa-truck-loading" />
    }

    return (
      <div
        id={`planning-${this.props.planning.id}`}
        className={this.cssClasses()}
        onDoubleClick={this.handleDoubleClick}
        data-planningid={this.props.planning.id}
      >
        <div className={`item-inner color ${pickupDepot.color_class}`}>
          <div className="item-content">
            {icon}
            <span>{pickupDepot.code}</span>
          </div>
        </div>
      </div>
    )
  },

  render () {
    if (this.props.planning.transport_id) {
      // Transport planning, do not render it
      return false
    } else if (!this.props.planning.order_id) {
      return this.renderManualPlanning()
    }

    const pickupDepot = this.pickupDepot()
    const returnDepot = this.returnDepot()

    return (
      <div
        id={`planning-${this.props.planning.id}`}
        className={this.cssClasses()}
        onDoubleClick={this.handleDoubleClick}
        data-orderid={this.props.planning.order_id}
      >
        <div className={`item-inner color ${pickupDepot.color_class}`}>
          <div className="item-content">
            <div className={`begin_time color ${pickupDepot.color_class}`}>
              <div className="inner">
                {Utils.Calendar.dateFormat(this.props.from, 'time')} {pickupDepot.code}
              </div>
            </div>
            <div className={`end_time color ${returnDepot.color_class}`}>
              <div className="inner">
                {returnDepot.code} {Utils.Calendar.dateFormat(this.props.till, 'time')}
              </div>
            </div>
            {this.props.planning.locked && (
              <a
                className="locked fa fa-lock"
                title={I18n.t('javascripts.locked_product')}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
})

module.exports = Planning
