const React = require('react')
const createReactClass = require('create-react-class')

const PopupTrigger = require('client/react/components/popup/popup_trigger')
const DestroyPickedupPlanning = require('./destroy_pickedup_planning')
const AccessoryTooltip = require('./accessory_tooltip').default
const AccessoryStockPlanningsActions = require('client/redux/actions/AccessoryStockPlanningsActions').default

/* eslint-disable react/jsx-no-bind */
const StockActions = createReactClass({
  displayName: 'StockActions',

  getInitialState () {
    return {
      showTooltip: false
    }
  },

  renderPopup () {
    return (
      <DestroyPickedupPlanning
        planning={this.props.planning}
        depots={this.props.depots}
        order={this.props.order}
        stock={this.props.stock}
      />
    )
  },

  renderPlan () {
    let disabled, disableReason

    if (!this.props.showPlan) {
      return null
    }

    // If the product is marked as accessory, do not render the plan button
    if (this.planningParentStockIds().includes(this.props.stock.id)) {
      return null
    }

    // Disable because of margin
    if (Utils.Planner.stockExceedsMaxMargin(this.props.stock, this.props.margin)) {
      disabled = true
      disableReason = I18n.t(
        'javascripts.stock_actions.product_value_exceeds_customer_margin',
        { value: Utils.Format.money(this.props.stock.value_in_cents) }
      )
    }

    // Disable because stock is not to be booked
    if (this.props.stock.can_book === false) {
      disabled = true
      disableReason = I18n.t('javascripts.stock_actions.product_not_rentable')
    }

    // Disable because of order status
    if (!Utils.Planner.orderCanAddStock(this.props.order.status)) {
      disabled = true
      disableReason = I18n.t('javascripts.stock_actions.product_cannot_be_added')
    }

    const handleClick = () => {
      return this.props.onPlan(this.props.stock, this.props.order).then(() => {
        // If the order is created in the webshop, and it has optional accessories plan those in as well
        if (this.props.order.webshop_order) {
          // Get all the accessory ids, based on the cart selected optional accessories
          const productAccessory = this.props.order.cart_product_with_accessories.filter(product => {
            if (product.product_id === this.props.stock.product_id && _.isEqual(product.accessory_ids, this.props.cartAccessoriesIds)) {
              return product.accessory_ids
            }
            return null
          })

          // Do nothing if the productAccessory array empty
          if (productAccessory.length === 0 || productAccessory === []) {
            return
          }
          if (productAccessory[0].accessory_ids.length === 0) {
            return
          }

          // Otherwise, plan them now that we have the accessory ids
          AccessoryStockPlanningsActions.create({
            order_id: this.props.planning.order_id,
            accessory_ids: productAccessory[0].accessory_ids,
            parent_id: this.props.planning.id,
            qty: productAccessory[0].qty
          }).then(() => {
            return this.setState({ showTooltip: true })
          }).catch(error => {
            return Flash.alert(error.error_message)
          })
        } else {
          return this.setState({ showTooltip: true })
        }
      })
    }

    // Render the link
    if (!disabled) {
      return <a className="plan-stock fa fa-plus" onClick={handleClick} />
    } else {
      return <a className="plan-stock fa fa-plus soft-disable" onClick={handleClick} title={disableReason} />
    }
  },

  renderUnplan () {
    let disabled, disableReason
    if (!this.props.showUnplan) {
      return null
    }

    // If the product is marked as accessory, do not render the unplan button
    if (this.planningParentStockIds().includes(this.props.stock.id)) {
      return null
    }

    // Disable because stock is locked to order
    if (this.props.planning?.locked) {
      disabled = true
      disableReason = I18n.t('javascripts.stock_actions.product_locked')
    }

    if (!disabled) {
      if (this.props.order.status === 'picked_up' || this.props.order.status === 'extended') {
        return <PopupTrigger component={this.renderPopup()}><a className="unplan-stock fa fa-times" /></PopupTrigger>
      } else {
        return <a className="unplan-stock fa fa-times" onClick={this.props.onUnplan.bind(null, this.props.planning)} />
      }
    } else {
      return <a className="unplan-stock fa fa-times soft-disable" title={disableReason} />
    }
  },

  handleClose () {
    return this.setState({ showTooltip: false })
  },

  planningParentStockIds () {
    const orderId = this.props.order?.id
    return this.props.plannings.map((planning) => {
      if (planning.parent_id && orderId && planning.order_id === orderId) {
        return planning.stock_id
      }
      return null
    })
  },

  render () {
    if (!this.props.canPlan) {
      return null
    }

    let unlockClasses = 'unlock-planning fa fa-lock'

    // Disables unlock action when user doesn't have permission
    if (!this.props.canLock) {
      unlockClasses += ' disabled'
    }

    return (
      <div className="actions">
        <AccessoryTooltip
          stock={this.props.stock}
          planning={this.props.planning}
          order={this.props.order}
          visible={this.state.showTooltip}
          onClose={this.handleClose}
        />
        {this.props.showLock && (
          <a
            className="lock-planning fa fa-lock"
            onClick={this.props.onLock.bind(null, this.props.planning)}
            title={`${I18n.t('javascripts.stock_actions.lock_product')}`}
          />
        )}
        {this.props.showUnlock && (
          <a
            className={unlockClasses}
            onClick={this.props.onUnlock.bind(null, this.props.planning)}
            title={`${I18n.t('javascripts.stock_actions.unlock_product')}`}
          />
        )}

        {this.renderPlan()}
        {this.renderUnplan()}
      </div>
    )
  }
})
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, props) => {
  let planning
  const depot = depots[props.stock.depot_id]

  if (props.isPlanned) {
    planning = state.orm.plannings.getAll({ stock_id: props.stock.id, order_id: props.order.id }).first()
  }

  return {
    depot,
    planning,
    showPlan: !props.isPlanned && depot.depot_type !== 'repair' && Utils.Calendar.canBookStock(props.stock, props.order.pickup_at, props.order.return_at),
    showUnplan: props.isPlanned,
    showLock: props.canLock && props.isPlanned && !(planning?.locked),
    showUnlock: props.isPlanned && planning?.locked,
    cartAccessoriesIds: state.calendar.cartAccessoriesIds,
    plannings: state.orm.plannings.getAll({ id: props.stock.plannings })
  }
}

module.exports = ReactRedux.connect(mapStateToProps)(StockActions)
