// React
import React, { Component } from 'react'
// import PropTypes from 'prop-types'

// Libraries
import find from 'lodash/find'

// Components
import LocationDateBadge from './LocationDateBadge'
import LocationDatePicker from './LocationDatePicker'

// Shared

/**
 * Specify order pickup and return time and location.
 *
 * @example
 *   <LocationDate
 *     datetime={moment()}
 *     timeslot={timeslot}
 *     depot={depot}
 *   />
 */

class LocationDate extends Component {
  static displayName = 'LocationDate'

  static propTypes = {}

  constructor (props) {
    super(props)

    this.state = {
      expand: false
    }
  }

  componentDidMount () {
    this.clickOutsideHandler = clickOutside($(ReactDOM.findDOMNode(this)), () => {
      this.setState({
        expand: false
      })
    })
  }

  componentWillUnmount () {
    $(document).off('click', this.clickOutsideHandler)
  }

  disabledDays = () => {
    return {
      before: this.props.minDate ? moment(this.props.minDate).toDate() : null
    }
  }

  handleToggle = () => {
    this.setState({
      expand: !this.state.expand
    })
  }

  handleChangeDate = (date) => {
    const hours = this.props.datetime.hours()
    const minutes = this.props.datetime.minutes()
    const datetime = moment(date).hours(hours).minutes(minutes)

    this.props.onChange({ datetime })
  }

  handleChangeTimeslot = (timeslot) => {
    const time = this.props.type === 'pickup' ? timeslot.from_time : timeslot.till_time
    const [hours, minutes] = time.split(':')
    const datetime = this.props.datetime.clone().hours(parseInt(hours)).minutes(parseInt(minutes))

    this.props.onChange({ datetime })
  }

  handleChangeDepot = (depot) => {
    this.props.onChange({ depot })
  }

  render () {
    const {
      depot, datetime, depots, timeslots, type, range, disabled, isDayDisabled,
      isTimeslotDisabled, isDepotDisabled, pickerOrientation, isSubmitting
    } = this.props

    // Find current timeslot
    const time = datetime.hours() * 100 + datetime.minutes()
    const timeslot = type === 'pickup'
      ? find(timeslots.toArray(), (t) => time >= t.from_time_integer && time < t.till_time_integer)
      : find(timeslots.toArray(), (t) => time > t.from_time_integer && time <= t.till_time_integer)

    return (
      <div className={`locations-dates ${disabled ? 'locations-dates__disabled' : ''}`}>
        <LocationDateBadge
          date={datetime.format('dd L')}
          time={timeslot ? timeslot.label : 'Unknown'}
          depot={depot}
          active={this.state.expand}
          onClick={this.handleToggle}
        />

        {this.state.expand &&
        <LocationDatePicker
          date={datetime.toDate()}
          timeslots={timeslots}
          depots={depots}
          selectedTimeslot={timeslot}
          selectedDepot={depot}
          onPickDate={this.handleChangeDate}
          onPickTimeslot={this.handleChangeTimeslot}
          onPickDepot={this.handleChangeDepot}
          range={range}
          isDayDisabled={isDayDisabled(type)}
          isTimeslotDisabled={isTimeslotDisabled(type)}
          isSubmitting={isSubmitting}
          isDepotDisabled={isDepotDisabled(type)}
          orientation={pickerOrientation}
        />}
      </div>
    )
  }
}

export default LocationDate
