const React = require('react')
const createReactClass = require('create-react-class')
const { DndProvider } = require('react-dnd')
const { HTML5Backend } = require('react-dnd-html5-backend')

const DragLayer = require('./viewport/drag_layer.jsx')
const Row = require('./viewport/row.jsx')
const Item = require('./item/item')
const Planning = require('./item/draggable_planning')
const Transport = require('./item/transport')

const Viewport = createReactClass({
  displayName: 'Viewport',
  mixins: [PureAwesomeMixin],

  componentDidMount () {
    this.viewPort = $('.gantt-viewport-inner')
    this.sidebar = $('.gantt-sidebar .gantt-rows')

    this.setWidth()
    this.synchronizedScrolling()

    if (this.props.center) {
      this.center(this.props.center)
    }
  },

  componentDidUpdate (prevProps) {
    this.setWidth()

    if (this.props.center) {
      if (!prevProps.center || prevProps.center.diff(this.props.center) !== 0) {
        this.center(this.props.center)
      }
    }
  },

  // Takes care of positioning of elements when scrolling.
  //
  synchronizedScrolling () {
    this.sidebar.scroll(this.handleSidebarScroll)
    this.viewPort.scroll(this.handleViewportScroll)
  },

  handleViewportScroll () {
    this.sidebar.scrollTop(this.viewPort.scrollTop())
    this.handleScroll()
  },

  handleSidebarScroll () {
    this.viewPort.scrollTop(this.sidebar.scrollTop())
    this.handleScroll()
  },

  // Handles scrolling within the viewport.
  //
  handleScroll () {
    // Make sure timestamps are fixed when scrolling
    $('.gantt-select-period .timestamps').css({ marginTop: this.viewPort.scrollTop() })

    const viewPortHeight = this.viewPort.height()
    const height = this.viewPort.find('.gantt-periods').height()
    const viewableHeight = height - viewPortHeight

    const offset = this.viewPort.scrollTop()

    if (viewableHeight - offset < 300) {
      return this.props.onScrollToBottom()
    } else {
      return false
    }
  },

  // Centers viewport on given date.
  //
  center (date) {
    const percentage = Utils.Calendar.calculatePeriodOffset(this.props.from, this.props.till, date)
    const width = $('.gantt-periods').width()
    let offset = width * (percentage / 100)
    offset -= $('.gantt-viewport').width() / 2

    $('.gantt-viewport').scrollLeft(offset)
  },

  // Force the width of the period to be the width of the top units bar.
  //
  setWidth () {
    let width = 0
    $('.gantt-period-units .units').each((i, el) => {
      width += $(el).width()
    })
    $(ReactDOM.findDOMNode(this)).width(width) // eslint-disable-line react/no-find-dom-node
  },

  renderPlanning (stock) {
    return this.props.planning.getAll({ stock_id: stock.id }).map(planning => {
      let from, order, till
      const inOrder = this.props.orderID === planning.order_id

      if (inOrder && this.props.order) {
        order = this.props.order
        from = this.props.order.pickup_at
        till = planning.returned_at || this.props.order.return_at
      } else {
        from = planning.pickup_at
        till = planning.returned_at || planning.return_at
      }

      const canSwap = planning.can_swap && planning.order_id

      return (
        <Item
          key={planning.id}
          periodFrom={this.props.from}
          periodTill={this.props.till}
          zoom={this.props.zoom}
          from={from}
          till={till}
        >
          <Planning
            planning={planning}
            canSwap={canSwap}
            inOrder={inOrder}
            order={order}
            from={from}
            till={till}
          />
        </Item>
      )
    })
  },

  renderTransport (stock) {
    return this.props.transports.getAll({ stock_id: stock.id }).map(transport => {
      const planning = this.props.planning.getById(transport.stock_planning_id)
      const plannings = this.props.planning.getAll({ transport_id: transport.id })

      return (
        <Item
          key={transport.id}
          periodFrom={this.props.from}
          periodTill={this.props.till}
          zoom={this.props.zoom}
          from={transport.picked_up_at || transport.pickup_at || moment(transport.available_at).subtract(4, 'hours')}
          till={transport.returned_at || transport.return_at || transport.available_at}
        >
          <Transport
            transport={transport}
            plannings={plannings}
            planning={planning}
          />
        </Item>
      )
    })
  },

  renderRows () {
    // Get all the main products
    const orderId = this.props.order?.id
    const planningParentStockIds = this.props.plannings.map((planning) => {
      if (planning.parent_id && orderId && planning.order_id === orderId) {
        return planning.stock_id
      }
      return null
    })

    return this.props.stock.map(stock => {
      const cssClasses = []
      if (stock.can_book === false) {
        cssClasses.push('cannot_book')
      }
      if (planningParentStockIds.includes(stock.id)) {
        cssClasses.push('optional_accessory')
      }

      return (
        <Row
          className={`${cssClasses.join(' ')}`}
          key={stock.id} stock={stock}
          onSwap={this.props.onSwap}
        >
          {this.renderPlanning(stock)}{this.renderTransport(stock)}
        </Row>
      )
    })
  },

  renderNow () {
    const now = moment()
    const left = Utils.Calendar.calculatePeriodOffset(this.props.from, this.props.till, now)
    return <div className="gantt-now" style={{ left: left + '%' }} />
  },

  render () {
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="gantt-viewport-inner">
          <div className="gantt-periods">
            <DragLayer />

            {this.props.children}
            {this.renderNow()}
            <div className="gantt-periods-rows">
              {this.renderRows()}
            </div>
          </div>
        </div>
      </DndProvider>
    )
  }
})

const mapStateToProps = (state, props) => {
  return {
    plannings: state.orm.plannings.getAll({ stock_id: props.stock.pluck('id') }),
    product_accessories: state.orm.product_accessories.getAll({ product_id: props.stock.pluck('product_id') })
  }
}

module.exports = ReactRedux.connect(mapStateToProps)(Viewport)
