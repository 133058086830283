import React from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import _ from 'lodash/fp';

import PhoneClientActions from 'client/redux/actions/phone_client';

const EmployeeFunction = (props) => {
  const phoneFunction = useSelector(_.get('phoneClient.function'));
  const setPhoneFunction = (value) => PhoneClientActions.setFunction(props.employeeId, value.value);

  const valueToOption = (value) => {
    if (value === '0') {
      return { value: '0', label: I18n.t('javascripts.phone_bar.none') };
    }

    return { value, label: I18n.t(`javascripts.phone_bar.${value}`) };
  }

  const options = [
    { value: '0', label: I18n.t('javascripts.phone_bar.none') },
    { value: 'inbound', label: I18n.t('javascripts.phone_bar.inbound') },
    { value: 'overflow', label: I18n.t('javascripts.phone_bar.overflow') },
    { value: 'ehbp', label: I18n.t('javascripts.phone_bar.ehbp') }
  ];

  return (
    <div id="phone-bar-function">
      <ReactSelect
        value={valueToOption(phoneFunction)}
        onChange={setPhoneFunction}
        options={options}
      />
    </div>
  );
}

export default EmployeeFunction;
